import React, { useState } from 'react';
import '../LeftColumn.css';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReCAPTCHA from "react-google-recaptcha";


function LeftColumn() {
    const [email, setEmail] = useState(''); // <-- Create state for the email
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [captchaValue, setCaptchaValue] = useState(null);
    const recaptchaRef = React.useRef(null);
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const handleCaptchaResponseChange = (value) => {
        setCaptchaValue(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!captchaValue) {
            setSnackbarMessage("Please complete the CAPTCHA.");
            setOpenSnackbar(true);
            return;
        }        
        
        try {
            const response = await axios.post('/api/submit-email', { 
                email: email, 
                captchaValue: captchaValue 
            });
            
            // Assuming your server responds with a message on a successful email submission
            setSnackbarMessage(response.data.message || "Email ontvangen!");
            setOpenSnackbar(true);
            recaptchaRef.current.reset(); // <-- Reset CAPTCHA here
            
            console.log(response.data.message);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setSnackbarMessage("Voer geldig emailadres in");
            } else {
                setSnackbarMessage("Er ging iets mis. Probeer het later nog eens.");
            }
            setOpenSnackbar(true);
            recaptchaRef.current.reset(); // <-- Reset CAPTCHA here too
    
            console.error('Error submitting email:', error);
        }
    };
    

    return (
        <div className="left-column">
            <img src="/MDA_Logo.svg" alt="MDA Logo" className="logo" />

            <p className="subheader">3 zinnen met wat Maarten Delen Advies is en doet. Dit is de subheader van MDA. Test</p>

            <hr className="divider" />

            <nav className="navigation">
                <NavLink to="/" className='navigation'>Home</NavLink>
                <NavLink to="/CV" className='navigation'>CV</NavLink>
                <NavLink to="/about" className='navigation'>Over Maarten</NavLink>
                <NavLink to="/contact" className='navigation'>Contact</NavLink>
            </nav>

            <hr className="divider" />

            <div className="newsletter-box">
                <h3>UKV’s direct in je mailbox ontvangen?</h3>
                <p>Vul hier je emailadres in.</p>
                <input 
                    className="newsletter-box input"
                    type="email"
                    placeholder="Email"
                    value={email} // <-- Bind the email state
                    onChange={e => setEmail(e.target.value)} // <-- Update state on change
                    required
                />
                <button className="btn" onClick={handleSubmit}>Verstuur</button>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={siteKey}  // Replace with your site key from the reCAPTCHA dashboard.
                    onChange={handleCaptchaResponseChange}
                />
            </div>

            <hr className="divider" />

            <p className="footer-info">Maarten Delen Advies <br></br>KvK: 83819541 <br></br>BTW: NL003876750B29 <br></br>Bank: NL 49 TRIO 0320 3203 16</p>
        
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
                action={
                    <>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        </div>
    );
}

export default LeftColumn;
